import './homeSection.css';
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from 'react-responsive';
import React, { useState, useRef } from 'react';

import { useEffect } from 'react';
import style from '../pages/home.module.css';

const HomeSection1 = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 554)' });
  const videoRef = useRef(null);
  const banner_txt_Ref = useRef(null);

  // useEffect(() => {
  //   banner_txt_Ref.current.classList.add('banner-zoom-in');
  // }, []);

  // function VideoPlayer_IOS() {
  //   return (
  //     <video muted playsinline autoplay loop width="100%" controls={false}>
  //       <source src={'/vi.mp4'} type="video/mp4" />
  //     </video>
  //   );
  // }

  const [deviceSystem, setDeviceSystem] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let system = '';

    if (userAgent.match(/Windows/i)) {
      system = 'Windows';
    } else if (userAgent.match(/Mac/i)) {
      system = 'macOS';
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      system = 'iOS';
    } else if (userAgent.match(/Android/i)) {
      system = 'Android';
    }

    setDeviceSystem(system);
  }, []);

  function VideoPlayer() {
    if (deviceSystem === 'iOS' && deviceSystem === 'macOS') {
      return (
        <video
          id="ios_vd"
          ref={videoRef}
          muted
          playsinline
          autoplay
          loop
          width="100%"
          controls={false}
        >
          <source src={'/vi.mp4'} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <video
          id="vd"
          muted
          playsInline
          autoPlay
          loop
          width="100%"
          controls={false}
        >
          <source src={'/vi.mp4'} type="video/mp4" />
        </video>
      );
    }
  }

  return (
    <div>
      <div>
        <div className={style.banner_video}>{VideoPlayer()}</div>
      </div>
    </div>
  );
};

export default HomeSection1;
