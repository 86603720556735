import './hotTopics.css';
import Banner from './component/Banner';
import HotTopicsSection from './page_section/HotTopicsSection';
import Footer from './component/Footer';
import { Helmet } from 'react-helmet';
import css from './pages/hottopics.module.css';

const HotTopics = ({ content, postData }) => {
  return (
    <div>
      <Helmet>
    <title>{content?.MT[4].metaTitle}</title>
    <meta name='description' content={content?.MT[4].metaDescription}/>
    <meta name='keywords' content={content?.MT[4].metaKeywords}/>
    </Helmet>        

      <div className={css.banner}>
        <h1>貸款迷思</h1>
      </div>

      <HotTopicsSection data={postData} />
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default HotTopics;
