import './homeSection.css';
import man from '../images/home/home_man2.jpg';
import CalculatorContent from '../component/CalculatorContent';
import ApplicationBtn from '../component/ApplicationBtn';
import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import applybtn from '../images/btn/btn_Component.png';

import '../css/animation.css';
import { useNavigate } from 'react-router-dom';

const HomeSection4 = () => {
  const text1Ref = useRef(null);
  const text2Ref = useRef(null);
  const text3Ref = useRef(null);
  const text4Ref = useRef(null);
  const btnRef = useRef(null);
  const manRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });

  const [scrollY, setScrollY] = useState(0);

  const nav = useNavigate();
  useEffect(() => {
    function handleScroll() {
      setScrollY(window.pageYOffset);
      //Desktop
      if (!isMobile) {
        if (scrollY >= 1300) {
          text1Ref.current.classList.add('slide-in');
          text2Ref.current.classList.add('slide-in');
          text3Ref.current.classList.add('slide-in-left');
          text4Ref.current.classList.add('slide-in-left');
          btnRef.current.classList.add('fade-in-frm-bottom');
          manRef.current.classList.add('fade-in-frm-bottom');
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);
  return (
    <div className="home-section4-container">
      <div className="home-section4-container-left">
        <div className="home-section4-container-left-description">
          <h1 style={{ opacity: 0 }} ref={text1Ref}>
            多種貸款服務
          </h1>
          <h3 ref={text2Ref} style={{ opacity: 0 }}>
            無論你想周轉定拓展業務
          </h3>
          <p ref={text3Ref} style={{ opacity: 0 }}>
            我們都可提供免信貸報告審批貸款
          </p>
          <h2 ref={text4Ref} style={{ opacity: 0 }}>
            不影響現有評級之餘亦可<span>快速獲批</span>
          </h2>
        </div>
        <div ref={btnRef} className="home-section4-container-left-apply-btn">
          <div style={{ position: 'relative' }}>
            <img
              src={applybtn}
              alt=""
              style={{ width: '30%', height: '20%', marginTop: '10px' }}
            />
            <button
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                color: 'white',
                fontSize: '24px',
              }}
              className="applyAnimBtn"
              onClick={() => {
                nav('/application');
              }}
            >
              立即申請
            </button>
          </div>
        </div>
      </div>
      <div className="home-section4-container-right">
        <img ref={manRef} className="phone_man" src={man} alt="" />
      </div>
    </div>
  );
};

export default HomeSection4;
