import Banner from './component/Banner';
import Footer from './component/Footer';
import bg from './images/loanService/loanservice_bg.png';
import { Helmet } from 'react-helmet';
const Disclaimer = ({ content }) => {
  return (
    <div className="disclaimer">
      <Helmet>
    <title>{content?.MT[7].metaTitle}</title>
    <meta name='description' content={content?.MT[7].metaDescription}/>
    <meta name='keywords' content={content?.MT[7].metaKeywords}/>
    </Helmet>
      {/* <Banner name={'免責聲明'} name_ch={''} bg_url={bg} /> */}
      <div className="container mt-5 mb-5">
        <h1 className="text-center mb-5">免責聲明</h1>
        <p>
          本網站（包括所連結的網頁）中部份產品及服務的資料發放可能於部份地區受到法律上限制，而此網站並非提供予置身於該等在法律上限制我們發放此等資料之地區人士使用。瀏覽此網站之人士，須自己負責暸解有關限制，假如閣下以任何形式使用本網站所提供的資料或其連結的功能，則會視為閣下向本公司表述其所在地區是沒有受到上述限制的。
        </p>
        <p>
          此等網頁只備作一般參考用途，雖然刊登此等資料時已力求準確，惟如有錯誤或遺漏，捷盈財務對該等資料之錯誤及遺漏將不會負任何責任。
        </p>
        <p>
          而此等網頁之資料不應被賴以作為專業意見。瀏覽此等網頁之人士，在需要時應尋求適當之專業意見，而不應視此等網頁之資料為可替代因個別情況而作出的詳細意見。
        </p>
        <p>捷盈財務有限公司可隨時停止或更改任何服務而毋須先行作出通知。</p>
      </div>
      <Footer />
    </div>
  );
};

export default Disclaimer;
