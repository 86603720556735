export function FormDatatext(text) {
  const newText = String(text)
    .split('\n')
    .map((str, indx) => (
      <span key={indx}>
        {str} <br />
      </span>
    ));
  return newText;
}
