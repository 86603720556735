import css from './aboutus.module.css';
import HomeSection5 from '../page_section/HomeSection5';
import Footer from '../component/Footer';
import { Helmet } from "react-helmet";
import bg from '../images/aboutus/aboutus_bg.jpg';
import circle_logo from '../images/circle_logo.png';
import square from '../images/squaregp.png';
import { useMediaQuery } from 'react-responsive';

const AboutUs = ({ content }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });

  return (
    <>
     <Helmet>
        <title>{content?.MT[5].metaTitle}</title>
        <meta name="description" content={content?.MT[5].metaDescription} />
        <meta name="keywords" content={content?.MT[5].metaKeywords} />
      </Helmet>
      {!isMobile && (
        <div className={css.container}>
         
          <img src={square} alt="" className={css.bg_square} />

          <div className={css.banner}>
            <h1>關於FAST CASH</h1>
          </div>

          <div className={css.content}>
            <img src={circle_logo} alt="" width={'300px'} className="mb-5" />
            <h1>致力為客戶提供專業的多元化貸款服務</h1>
            <h5>
              無論您所需的是資金周轉抑或業務拓展，我們團隊都會因應客戶不同需要提供靈活、貼心貸款方案，務求助您輕鬆應付各種財務需要，盡享便利資金周轉。
            </h5>
          </div>
          <HomeSection5 />
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={css.container}>
          <div className={css.banner}>
            <h1>關於FAST CASH</h1>
          </div>

          <div className={css.content}>
            <img src={square} alt="" className={css.bg_square} />
            <img src={circle_logo} alt="" width={'200'} className="mb-5" />
            <h2>致力為客戶提供專業的多元化貸款服務</h2>
            <p>
              無論您所需的是資金周轉抑或業務拓展，我們團隊都會因應客戶不同需要提供靈活、貼心貸款方案，務求助您輕鬆應付各種財務需要，盡享便利資金周轉。
            </p>
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default AboutUs;
