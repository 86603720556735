import './home.css';
import HomeSection1 from './page_section/HomeSection1';
import { useEffect, useState, useRef } from 'react';
import WhyChoose from './component/WhyChoose';
import HomeSection2 from './page_section/HomeSection2';
import HomeSection3 from './page_section/HomeSection3';
import Footer from './component/Footer';
import { Helmet } from 'react-helmet';
import HomeSection4 from './page_section/HomeSection4';
import HomeSection5 from './page_section/HomeSection5';
import homebg4 from './images/home/homebg4.png';
import style from './pages/home.module.css';
import EstimateForm from './component/EstimateForm';
import ApplicationForm from './component/ApplicationForm/ApplicationForm';
import { useMediaQuery } from 'react-responsive';
import './css/animation.css';

//mobile version

import CalculatorContent from './component/CalculatorContent';
import man from './images/home/home_calculator_background.png';
import dot_bg from './images/caltor_dot_bg.png';

const Home = ({ content }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });
  const [videoPlay, setVideoPlay] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {    
    setVideoPlay(true);
    return () => {};
  }, []); 
  

  const bgmanRef = useRef(null);
  const formRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);

  //mobile animation
  const calterRef = useRef(null);
  const text1Ref = useRef(null);
  const manRef = useRef(null);
  const text2Ref = useRef(null);
  const text3Ref = useRef(null);
  const text4Ref = useRef(null);
  const text5Ref = useRef(null);
  const applyRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.pageYOffset);
      //Desktop
      if (!isMobile) {
        if (scrollY >= 1300) {
          bgmanRef.current.classList.add('fade-in');
        }
        if (scrollY >= 1600) {
          formRef.current.classList.add('fade-in-frm-bottom');
        }
      }

      //mobile
      if (isMobile) {
        if (scrollY >= 100) {
          calterRef.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 350) {
          text1Ref.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 500) {
          manRef.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 700) {
          text2Ref.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 900) {
          text3Ref.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 1100) {
          text4Ref.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 1300) {
          text5Ref.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY >= 1500) {
          applyRef.current.classList.add('fade-in-frm-bottom');
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY,isMobile]);

  const [deviceSystem, setDeviceSystem] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let system = '';

    if (userAgent.match(/Windows/i)) {
      system = 'Windows';
    } else if (userAgent.match(/Mac/i)) {
      system = 'macOS';
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      system = 'iOS';
    } else if (userAgent.match(/Android/i)) {
      system = 'Android';
    }
    setDeviceSystem(system);
  }, []);

  function VideoPlayer() {
    if (deviceSystem === 'iOS' && deviceSystem === 'macOS') {
      return (
        <video
          id="ios_vd"
          ref={videoRef}
          muted
          playsinline
          autoplay
          loop
          width="100%"
          controls={false}
        >
          <source src={'/vi_mob.mp4'} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <video
          id="vd"
          muted
          playsInline
          autoPlay
          loop
          width="100%"
          controls={false}
        >
          <source src={'/vi_mob.mp4'} type="video/mp4" />
        </video>
      );
    }
  }

  return (
    <div className="home-container">
      <Helmet>
    <title>{content?.MT[0].metaTitle}</title>
    <meta name='description' content={content?.MT[0].metaDescription}/>
    <meta name='keywords' content={content?.MT[0].metaKeywords}/>
    </Helmet>      
      {!isMobile && (
        <>
          <div className="section1_container section1_bg">
            <HomeSection1 />
          </div>
          <div className="section2_container section2_bg">
            <HomeSection2 />
          </div>
          <div className="section4_container">
            <img ref={bgmanRef} src={homebg4} alt="" className="homebg4" />
            <HomeSection4 />
          </div>

          <div
            ref={formRef}
            style={{
              marginTop: '10rem',
              opacity: '0',
            }}
          >
            <ApplicationForm />
          </div>
          <div className="section5_container section5_bg">
            <HomeSection5 />
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div
            style={{ paddingTop: ' 0rem' }}
            className={style.mob_banner_video}
          >
            {VideoPlayer()}
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              opacity: 0,
            }}
            ref={calterRef}
          >
            <CalculatorContent />
          </div>

          <div className={`mt-5 ${style.mob_title}`}>
            <img className={style.dot_bg} src={dot_bg} alt="" />
            <p style={{ opacity: 0 }} ref={text1Ref}>
              可選擇透過網上平台完成整個貸款申請過程，
              <br />
              即時提取現金。
            </p>
          </div>
          <img
            ref={manRef}
            style={{ opacity: 0 }}
            className={style.man}
            src={man}
            alt=""
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '5px',
            }}
          >
            <div className={style.text2}>
              <h1 style={{ opacity: 0 }} ref={text2Ref}>
                多種貸款服務
              </h1>
              <h3 style={{ opacity: 0 }} ref={text3Ref}>
                無論你想周轉定拓展業務
              </h3>
              <p style={{ opacity: 0 }} ref={text4Ref}>
                我們都可提供免信貸報告審批貸款
              </p>
              <h2 ref={text5Ref} style={{ color: '#444444', opacity: 0 }}>
                不影響現有評級之餘亦可
                <span style={{ color: '#9dd75e' }}>快速獲批</span>
              </h2>
            </div>
          </div>
          <div ref={applyRef} style={{ opacity: 0 }} className="mb-5">
            <ApplicationForm />
          </div>
        </>
      )}
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
