import "./footer.css"
import icon from "../images/logo.png"
import { HashLink } from "react-router-hash-link"
import { useMediaQuery } from "react-responsive"
import { ENV } from "../config"
import { useEffect, useState } from "react"
import useFetch from "../codeHelper/useFetch"
import { FormDatatext } from "../codeHelper/useFormatDatatext"

const Footer = () => {
  const isMobileTablet = useMediaQuery({ query: "(max-width: 1212px)" })

  const { data } = useFetch(ENV + "/api/v1/content")

  const [phone, setPhone] = useState("")
  const [footer1, setFooter1] = useState("")
  const [address, setAddress] = useState("")
  const [licence, setLicence] = useState("")
  const [hotline, setHotline] = useState("")

  useEffect(() => {
    setPhone(data?.MT.find((item) => item.title === "phone")?.description)
    setFooter1(data?.MT.find((item) => item.title === "footer1")?.description)
    setAddress(data?.MT.find((item) => item.title === "address")?.description)
    setLicence(data?.MT.find((item) => item.title === "licence")?.description)
    setHotline(data?.MT.find((item) => item.title === "hotline")?.description)
  }, [data, phone, footer1, address, licence, hotline])

  function formatPhoneNumber(number) {
    if (!number || typeof number !== "string") {
      return "" // or return a default value or handle this case differently
    }
    return number.slice(0, 4) + " " + number.slice(4)
  }

  const commonLink_list = [
    {
      title: "主頁",
      url: "/",
    },
    {
      title: "關於 FAST CASH",
      url: "/about",
    },
    {
      title: "免責聲明",
      url: "/免責聲明",
    },
    {
      title: "隱私政策",
      url: "/隱私政策",
    },
    {
      title: "個人資料收集聲明",
      url: "/個人資料收集聲明",
    },
    {
      title: "放債人條例",
      url: "/放債人條例",
    },
  ]

  const loanServices_list = [
    {
      title: "私人貸款",
      url: "/私人貸款",
    },
    {
      title: "按揭及業主貸款",
      url: "/按揭及業主貸款",
    },
    {
      title: "結餘轉戶",
      url: "/結餘轉戶",
    },
    {
      title: "申請表格",
      url: "/application",
    },
  ]

  return (
    <div className="footer">
      {!isMobileTablet && (
        <div className="footer-container">
          <div className="ft1">
            <a href="/">
              <img className="footer-icon" src={icon} alt="" />
            </a>
          </div>
          <div className="ft2">
            <div style={{ marginTop: "1rem" }}>
              {/* <p>常用連結</p> */}
              {commonLink_list.map((item, index) => (
                <div key={index} className="mb-3">
                  <a href={item.url}>{item.title}</a>
                </div>
              ))}
            </div>
            <div
              style={{ marginLeft: "5vw", marginTop: "1rem" }}
              className="footer-loanServices"
            >
              <p>貸款服務</p>
              {loanServices_list.map((item, index) => (
                <div key={index} className="mb-3">
                  <a href={item.url}>{item.title}</a>
                </div>
              ))}
            </div>
          </div>
          <div className="ft3">
            <a href={`tel:+852${hotline}`}>
              <p>查詢熱線：+852 {formatPhoneNumber(hotline)}</p>
            </a>
            <a href={`https://wa.me/+852${phone}`}>
              <p>Whatsapp：+852 {formatPhoneNumber(phone)}</p>
            </a>
            <a href={`tel:+852${hotline}`}>
              <p>投訴熱線：+852 {formatPhoneNumber(hotline)}</p>
            </a>
            <div className="footer-statement">{FormDatatext(footer1)}</div>
          </div>
          <div className="ft4">
            <p>地址：{address}</p>
            {/* <p>營業時間：10 : 00 - 22 : 00</p> */}
            <p>Money Lenders Licence: {licence}</p>
          </div>
          <div className="ft5"></div>
          <div className="ft6">
            <div className="d-flex flex-column text-center pb-2">
              <h4>忠告：借錢梗要還，咪俾錢中介</h4>
              <p>
                © Copyright© 快易錢 Fast Profit Finance Ltd. All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobileTablet && (
        <div className="footer-container-mob">
          <a href="/">
            <img className="footer-icon" src={icon} alt="" />
          </a>
          <div className="footer-contact-mob">
            <a href={`tel:+852${hotline}`}>
              <p>查詢熱線：+852 {formatPhoneNumber(hotline)}</p>
            </a>
            <a href={`https://wa.me/+852${phone}`}>
              <p>Whatsapp：+852 {formatPhoneNumber(phone)}</p>
            </a>
            <p>忠告：借錢梗要還，咪俾錢中介</p>
            <a href={`tel:+852${hotline}`}>
              投訴熱線：+852 {formatPhoneNumber(hotline)}
            </a>
          </div>

          <div className="footer-link-mob">
            <div className="d-flex flex-column">
              <p className="footer-header-mob">常用連結</p>
              {commonLink_list.map((item, index) => (
                <div key={index} className="mb-2 text-center">
                  <a href={item.url}>{item.title}</a>
                </div>
              ))}
            </div>
            <div className="d-flex flex-column">
              <p className="footer-header-mob">貸款服務</p>
              {loanServices_list.map((item, index) => (
                <div key={index} className="mb-2 text-center">
                  <a href={item.url}>{item.title}</a>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5">
            <p>地址：{address}</p>
            {/* <p>營業時間：10 : 00 - 22 : 00</p> */}
            <p>Money Lenders Licence: {licence}</p>
          </div>
          <div className="footer-statement">{FormDatatext(footer1)}</div>
        </div>
      )}
    </div>
  )
}

export default Footer
