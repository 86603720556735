import '../mortgageLoan/mortgageLoan.css';
import HomeSection1 from '../page_section/HomeSection1';
import WhyChoose from '../component/WhyChoose';
import HomeSection2 from '../page_section/HomeSection2';
import HomeSection3 from '../page_section/HomeSection3';
import HomeSection5 from '../page_section/HomeSection5';
import PrivateLoanSection1 from '../page_section/PrivateLoanSection1';
import MortgageLoanSection1 from '../page_section/MortgageLoanSection1';
import MortgageLoanSection2 from '../page_section/MortgageLoanSection2';
import Banner from '../mortgageLoan/Banner';
import Footer from '../component/Footer';
import { Helmet } from 'react-helmet';
import bg from '../images/mortgageLoan/mortgage_banner.jpg';
import square from '../images/squaregp.png';
import ApplicationForm from '../component/ApplicationForm/ApplicationForm';
import style from './mortgageLoan.module.css';
import { useEffect } from 'react';

//mobile
import { useMediaQuery } from 'react-responsive';
import BannerSquare from '../component/BannerSquare/BannerSquare';
import squaregp_bg from '../images/squaregp.png';
import icon1 from '../images/mortgageLoan/icon1.png';
import icon2 from '../images/mortgageLoan/icon2.png';
import icon3 from '../images/mortgageLoan/icon3.png';

const MortgageLoan = ({ content }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });
  const data = [
    {
      icon: icon1,
      text: '免入息證明',
    },
    {
      icon: icon2,
      text: '免公司財務報表',
    },
    {
      icon: icon3,
      text: '即時批核 24小時',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{content?.MT[2].metaTitle}</title>
        <meta name="description" content={content?.MT[2].metaDescription} />
        <meta name="keywords" content={content?.MT[2].metaKeywords} />
      </Helmet>
      {!isMobile && (
        <div className="PrivateLoan">
        
          <div className="MortgageLoan-section1-container">
            <Banner
              name={'Private Loan'}
              name_ch={'按揭及業主貸款'}
              bg_url={bg}
            />
          </div>
          <div
            className="MortgageLoan-section2-container"
            style={{ position: 'relative' }}
          >
            <img
              src={square}
              alt=""
              style={{
                position: 'absolute',
                top: '-29rem',
                scale: '0.5',
                left: '-20rem',
              }}
            />
            <PrivateLoanSection1
              title={
                '為客戶提供「一按」、「二按」及「轉按」服務。相對銀行繁複的審批過程，我們的貸款計劃能為閣下提供彈性的貸款服務。'
              }
              desc={''}
            />
          </div>
          <div className="MortgageLoan-section5-container">
            <MortgageLoanSection1 />
          </div>

          <div className="MortgageLoan-section6-container">
            <MortgageLoanSection2 />
          </div>

          <div className="MortgageLoan-section3_container section3_bg">
            <ApplicationForm />
          </div>
          <div className="MortgageLoan-section4_container section5_bg">
            <HomeSection5 />
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}

      {isMobile && (
        <div className={style.container}>
          <div className={style.banner}>
            <h2 className={style.banner_text}>按揭及業主貸款</h2>
          </div>

          <BannerSquare
            header={'按揭及業主貸款'}
            data={data}
            width={'50%'}
            height={'300px'}
            top={'unset'}
          />

          <div className={style.text1}>
            <img src={squaregp_bg} alt="" className={style.squaregp_bg} />
            <h4>
              為客戶提供「一按」、「二按」及「轉按」服務。相對銀行繁複的審批過程，我們的貸款計劃能為閣下提供彈性的貸款服務。
            </h4>
          </div>
          <div className={style.text2}>
            <p>
              註: 根據《放債人條例》年利率最高不超過48%。
              供款期由6個月至個120月常見還款例子:
              年利率為30%情況下的貸款HK$10,000，還款期數12期，每期還款額為
              HK$965
            </p>
          </div>

          <div className={`${style.text3} mt-3`}>
            <h2>轉按</h2>
            <h4>
              「轉按」是指物業已在銀行或貸款機構承做按揭，而業主將該物業的按揭轉至另一銀行或貸款機構。
            </h4>
            <p>
              業主只需要提供原有按揭機構的貸款合約，為戶減少利息支出，大大減輕供款負擔。業主亦可藉著轉按套現繳付昂貴的咭數，便可考慮轉按來減輕咭數利息支出。
            </p>
          </div>
          <div className={`${style.text3} mt-3`}>
            <h2>二按</h2>
            <h4>
              「二按」是指物業本身已在銀行或貸款機構做按揭並透過該物業再申請貸款。
            </h4>
            <p>
              業主可利用已做按揭物業，申請二按貸款，最高借足8成半或以上，免屋契，即日取款，為客戶靈活周轉。
              我們的二按貸款計劃，可將已按的物業加按貸款，無需樓契抵押，透過簡單審查程序，利息特低。
            </p>
          </div>
          <div className={`${style.text3} mt-3`}>
            <h2>一按</h2>
            <h4>
              「一按」是指物業新做的按揭，樓契需要用作抵押品給銀行或貸款機構。
            </h4>
            <p>
              任何物業不限種類及樓齡最高可借8成半或以上，免入息證明及繁複文件，即時電話批核，比銀行簡單快捷。如已擁有供滿按揭的物業，亦可透過我們的一按貸款計劃套現大額資金，以作資金周轉。相對銀行繁複的審批過程，我們的一按貸款計劃能為閣下提供彈性的貸款服務。
            </p>
          </div>
          <div>
            <ApplicationForm />
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default MortgageLoan;
