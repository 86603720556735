import { useEffect } from "react"
import PropTypes from "prop-types"

const GoogleAdsConversion = ({ id, label }) => {
  useEffect(() => {
    if (!id || !label) return

    // 加載 Google Ads 轉換跟蹤腳本
    const script = document.createElement("script")
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    script.async = true
    document.head.appendChild(script)

    // 初始化並配置 gtag
    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("js", new Date())
      gtag("config", id)

      // 发送转化事件
      gtag("event", "conversion", {
        send_to: `${id}/${label}`,
      })
    }
  }, [id, label])

  return null
}

GoogleAdsConversion.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default GoogleAdsConversion
