import './homeSection.css';
import icon1 from '../images/icon/calculator_white.png';
import icon2 from '../images/icon/house_white.png';
import icon3 from '../images/icon/dollar_white.png';
import image3 from '../images/balancetransfer/balance_bg.jpg';
import image2 from '../images/home/home_man2.jpg';
import image1 from '../images/privateLoan/bg.jpg';
import green_bg from '../images/green_bg.png';
import { useNavigate, useLocation } from 'react-router-dom';
import knowmore from '../images/btn/knowmore.png';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

const light = '#C9E575';
const dark = '#83954E';

const HomeSection5 = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });

  const [tabInput, setTabInput] = useState('1');
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const [link, setLink] = useState('');

  const [pic, setPic] = useState('');
  const [bg1, setBg1] = useState(dark);
  const [bg2, setBg2] = useState(light);
  const [bg3, setBg3] = useState(light);

  const handleNavigate = () => {
    navigate(link);
  };

  const info = () => (
    <div className="section5-main-word">
      <h4>{header}</h4>
      <p>{content}</p>

      <div className="mt-4" onClick={handleNavigate}>
        <img src={knowmore} className="little_arrow" alt="" />
        <span style={{ cursor: 'pointer' }}>了解更多</span>
      </div>
    </div>
  );

  const tabpic = () => (
    <div className="section5-main-img">
      <img src={pic} alt="" ref={imgRef} style={opacity_style} />
    </div>
  );

  const handleChangeTab = useCallback(() => {
    if (tabInput === '1') {
      setBg1(dark);
      setBg2(light);
      setBg3(light);
      setHeader('私人貸款');
      setContent(
        '卡數、貸款、透支集中處理，按還款能力制定還款年期更輕鬆，餘額可自由運用。'
      );
      setLink('/私人貸款');

      setPic(image1);
    } else if (tabInput === '2') {
      setBg1(light);
      setBg2(dark);
      setBg3(light);
      setHeader('按揭及業主貸款');
      setContent(
        '為客戶提供「一按」、「二按」及「轉按」服務。相對銀行繁複的審批過程，我們的貸款計劃能為閣下提供彈性的貸款服務。'
      );
      setLink('/按揭及業主貸款');
      setPic(image2);
    } else if (tabInput === '3') {
      setBg1(light);
      setBg2(light);
      setBg3(dark);
      setHeader('結餘轉戶');
      setContent(
        '卡數、貸款、透支集中處理，按還款能力制定還款年期更輕鬆，餘額可自由運用。'
      );
      setPic(image3);
      setLink('/結餘轉戶');
    }
  }, [tabInput]);

  useEffect(() => {
    handleChangeTab();
  }, [tabInput, handleChangeTab]);

  const tabRef = useRef(null);
  const greenRef = useRef(null);
  const imgRef = useRef(null);
  const textRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);

  const location = useLocation();
  const opacity_style =
    location.pathname === '/' ? { opacity: '0' } : { opacity: '1' };

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.pageYOffset);

      if (!isMobile) {
        if (scrollY > 2200 && location.pathname === '/') {
          tabRef.current.classList.add('fade-in');
          greenRef.current.classList.add('fade-in');
          imgRef.current.classList.add('fade-in-frm-bottom');
          textRef.current.classList.add('slide-in-left');
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <div className="home-section5">
      <div className="home-section5-container">
        <div ref={tabRef} className="section5-tab-row" style={opacity_style}>
          <div
            className="section5-tab"
            style={{ background: bg1, cursor: 'pointer' }}
            onClick={() => {
              setTabInput('1');
            }}
          >
            <img src={icon1} alt="" />
            <span>私人貸款</span>
          </div>
          <div className="section5-space"></div>
          <div
            className="section5-tab"
            style={{ background: bg2, cursor: 'pointer' }}
            onClick={() => {
              setTabInput('2');
            }}
          >
            <img src={icon2} alt="" />
            <span>按揭及業主貸款</span>
          </div>
          <div className="section5-space"></div>
          <div
            className="section5-tab"
            style={{ background: bg3, cursor: 'pointer' }}
            onClick={() => {
              setTabInput('3');
            }}
          >
            <img src={icon3} alt="" />
            <span>結餘轉戶</span>
          </div>
        </div>
        <div className="section5-main section5_main_bg">
          <div className="section5-main-container">
            <img
              ref={greenRef}
              src={green_bg}
              alt=""
              className="green_bg"
              style={opacity_style}
            />
            <div className="section5-main-content">
              {tabpic()}
              <div
                ref={textRef}
                className="section5-main-content-right"
                style={opacity_style}
              >
                {info()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection5;
