import './privateLoan.css';
import style from './privateLoan.module.css';
import HomeSection1 from './page_section/HomeSection1';
import WhyChoose from './component/WhyChoose';
import HomeSection2 from './page_section/HomeSection2';
import HomeSection3 from './page_section/HomeSection3';
import HomeSection5 from './page_section/HomeSection5';
import PrivateLoanSection1 from './page_section/PrivateLoanSection1';
import Banner from './component/Banner';
import Footer from './component/Footer';
import { Helmet } from 'react-helmet';
import bg from './images/privateLoan/bg.jpg';
import square from './images/squaregp.png';
import ApplicationForm from './component/ApplicationForm/ApplicationForm';
import { useMediaQuery } from 'react-responsive';
import BannerSquare from './component/BannerSquare/BannerSquare';

//mobile
import icon1 from './images/privateLoan/icon1.png';
import icon2 from './images/privateLoan/icon2.png';
import icon3 from './images/privateLoan/icon3.png';
import icon4 from './images/privateLoan/icon4.png';
import squaregp_bg from './images/squaregp.png';
import { useEffect } from 'react';

const PrivateLoan = ({ content }) => {
  useEffect(() => {
    window.scrollTo(0, 0);    
  }, []);
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });

  const data = [
    {
      icon: icon1,
      text: '申請費用全免',
    },
    {
      icon: icon2,
      text: '還款期長達48個月',
    },
    {
      icon: icon3,
      text: '貸款額高達月薪10倍',
    },
    {
      icon: icon4,
      text: '最快30分鐘現金到手',
    },
  ];

  return (
    <>
        <Helmet>
        <title>{content?.MT[1].metaTitle}</title>
        <meta name="description" content={content?.MT[1].metaDescription} />
        <meta name="keywords" content={content?.MT[1].metaKeywords} />
      </Helmet>
      {!isMobile && (
        <div className={style.container}>
      
          <img src={square} alt="" className="square-group" />
          <div className="PrivateLoan-section1-container">
            <Banner name={'Private Loan'} name_ch={'私人貸款'} bg_url={bg} />
          </div>
          <div className="PrivateLoan-section2-container">
            <PrivateLoanSection1
              title={
                '為客戶提供一個特快的「網上」貸款體驗。客人可選擇透過網上平台完成整個貸款申請過程，並即時提取現金。'
              }
              desc={
                '過往的信貸紀錄不會影響你的申請，私人貸款服務配合不同人士需要，申請貸款從未如此輕鬆簡單。我們為客戶提供全面的財務管理方案更可享低息及長還款期，讓您可靈活理財。'
              }
            />
          </div>

          <ApplicationForm />
          <div className="section4_container section5_bg">
            <HomeSection5 />
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={style.container}>
          <div className={style.banner}>
            <h2 className={style.banner_text}>私人貸款</h2>
          </div>

          <BannerSquare
            header={'私人貸款'}
            data={data}
            width={'50%'}
            height={'300px'}
            top={'unset'}
          />

          <div className={style.text1}>
            <img src={squaregp_bg} alt="" className={style.squaregp_bg} />
            <h4>
              為客戶提供一個特快的「網上」貸款體驗。
              <br />
              客人可選擇透過網上平台完成整個貸款申請過程，並即時提取現金。
            </h4>
            <p>
              過往的信貸紀錄不會影響你的申請，私人貸款服務配合不同人士需要，申請貸款從未如此輕鬆簡單。我們為客戶提供全面的財務管理方案更可享低息及長還款期，讓您可靈活理財。
            </p>
          </div>
          <div className={style.text2}>
            <p>
              註: 根據《放債人條例》年利率最高不超過48%。
              供款期由6個月至個120月常見還款例子:
              年利率為30%情況下的貸款HK$10,000，還款期數12期，每期還款額為
              HK$965
            </p>
          </div>
          <div>
            <ApplicationForm />
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default PrivateLoan;
