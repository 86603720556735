import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import './navbar.css';
import logo from '../images/FC-logo.png';
import ApplicationBtn from './ApplicationBtn';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const NavBar = ({ content, postData, props }) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 990px) and (min-width: 549px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 550px)',
  });

  const ApplyButton = styled(Button)({
    color: '#FFF',
    backgroundColor: '#A2C43A',
    fontFamily: 'pingFangSmall!important',
    width: '100px',
    height: '35px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    borderRadius: '22px',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#8eac33',
      transform: 'translateY(2px)',
    },
  });
  const navigate = useNavigate();
  const handleApplyBtn = () => {
    navigate('/application');
  };

  const ApplyArea = () => {
    return <ApplyButton onClick={handleApplyBtn}>立即申請</ApplyButton>;
  };
  // const servicesDrpList = [
  //   { title: "免TU貸款", link: "/tu" },
  //   { title: "物業一按 | 二按", link: "/mortgage" },
  //   { title: "中小企周轉貸款", link: "/smeloan" },
  //   { title: "私人借貸 | 循環貸款", link: "/privateLending" },
  //   { title: "清卡數 | 稅貸", link: "/creditcard" },
  //   { title: "網上貸款 | 極速批核", link: "/onlineLoan" },
  // ]

  // const servicesDrpList = [
  //   { title: content?.loanService[0].title, link: "/tu" },
  //   { title: content?.loanService[1].title, link: "/mortgage" },
  //   { title: content?.loanService[2].title, link: "/smeloan" },
  //   { title: content?.loanService[3].title, link: "/privateLending" },
  //   { title: content?.loanService[4].title, link: "/creditcard" },
  //   { title: content?.loanService[5].title, link: "/onlineLoan" },
  // ]

  // const servicesDrpList = [
  //   { title: content?.loanService[0].title, link: "/tu" },
  //   { title: content?.loanService[1].title, link: "/mortgage" },
  //   { title: content?.loanService[2].title, link: "/smeloan" },
  //   { title: content?.loanService[3].title, link: "/privateLending" },
  //   { title: content?.loanService[4].title, link: "/creditcard" },
  //   { title: content?.loanService[5].title, link: "/onlineLoan" },
  // ];

  const [blogPostTitle, setBlogPostTitle] = useState('');
  const [blogPostOrdering, setBlogPostOrdering] = useState('');
  let servicesDrpList_id = 0;
  // const blogPostTitle = postData ? postData[postData.length - 1].title : '';

  // const blogPostOrdering = postData
  //   ? postData[postData.length - 1].ordering
  //   : '';

  useEffect(() => {
    setBlogPostTitle(postData ? postData[postData.length - 1].title : '');
    setBlogPostOrdering(postData ? postData[postData.length - 1].ordering : '');
  }, [postData]);

  return (
    <div className="navbar-container">
      <Navbar bg="white" variant="white" expand="lg" fixed="top">
        <Container>
          <div className="nav-logo-group">
            <Navbar.Brand href="/">
              <img className="nav-logo" src={logo} alt="" />
            </Navbar.Brand>
            {isMobile && (
              <div
                className="align-self-center"
                style={{ transform: 'scale(0.8)' }}
              >
                <a title="" href="/application">
                  {ApplyArea()}
                </a>
              </div>
            )}
            {isTablet && (
              <div className="align-self-center ms-1">
                <a title="" href="/application">
                  {ApplyArea()}
                </a>
              </div>
            )}
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="flex-column">
            <Nav className="ms-auto nav-text mt-2 nav-link">
              <Nav.Link href="/私人貸款">私人貸款</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="/按揭及業主貸款">按揭及業主貸款</Nav.Link>
              <Nav.Link href="/結餘轉戶">結餘轉戶</Nav.Link>
              {postData && postData.length > 0 && (
                <Nav.Link
                  href={
                    '/hottopics/' +
                    blogPostOrdering +
                    '/' +
                    String(blogPostTitle).replace(/\s/g, '')
                  }
                >
                  貸款迷思
                </Nav.Link>
              )}
              {/* <Nav.Link href={"/hottopics/"}>貸款迷思</Nav.Link> */}
              <Nav.Link href="/about">關於 FAST CASH</Nav.Link>
              {/* <Nav.Link
                href="https://fastcash-proj.web.app/"
                className="loginBtn"
              >
                現有客戶登入
              </Nav.Link> */}
              <Nav.Link href="/application">{ApplyArea()}</Nav.Link>
              {/* <div className="dropdown-divider"></div> */}
              {/* <Nav.Link href={"/hottopics/" + blogPostOrdering + "/" + String(blogPostTitle).replace(/\s/g, "")}>{content?.menu[4].label}</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
