export function isValidHKID(hkid) {
  const regex = /^[A-Z]{1,2}[0-9]{6}[A0-9]$/
  if (!regex.test(hkid)) {
    // Check if the input matches the format of a HKID card
    return false
  }

  return true
}

export function findDescriptionByTitle(data, title) {
  if (!data || !Array.isArray(data)) return ""
  return data.find((item) => item.title === title)?.description
}
