// GoogleTagManager.js
import { useEffect } from "react"
import PropTypes from "prop-types"

const GoogleTagManager = ({ id }) => {
  useEffect(() => {
    if (!id) return

    // 加載 Google Tag Manager 腳本
    const script = document.createElement("script")
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    script.async = true
    document.head.appendChild(script)

    // 初始化 gtag
    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("js", new Date())
      gtag("config", id)
    }
  }, [id])

  return null
}

GoogleTagManager.propTypes = {
  id: PropTypes.string.isRequired,
}

export default GoogleTagManager
