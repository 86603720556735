import { useState, useRef, useEffect } from "react"
import "../estimateForm.css"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { Button, FormControl, Grid, Menu } from "@mui/material"
import { styled } from "@mui/material/styles"

import CurrencyInput from "react-currency-input-field"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

import Checkbox from "@mui/material/Checkbox"
import { FormDatatext } from "../../codeHelper/useFormatDatatext"
import { useMediaQuery } from "react-responsive"
import { isMobilePhone } from "validator"
import moment from "moment"
import { ENV } from "../../config"
import css from "./applicationForm.module.css"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import square from "../../images/form_square.png"
import { useNavigate } from "react-router-dom"
import { isValidHKID } from "../../codeHelper/utls"
import axios from "axios"

const SubmitButton = styled(Button)({
  backgroundColor: "#77BF27",
  width: "50px",
  height: "35px",
  fontSize: "15px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#707070",
  },
})
const ApplicationForm = () => {
  const FormLabel = styled(Typography)({
    fontFamily: "pingFangSmall!important",
  })

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 556px)",
  })

  const [ipAddress, setIpAddress] = useState("")
  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json")
      const response64 = await axios.get("https://api64.ipify.org?format=json")

      if (response) {
        setIpAddress(response.data.ip)
      } else {
        setIpAddress(response64.data.ip)
      }
    } catch (error) {
      console.error("Error fetching IP address:", error)
    }
  }
  useEffect(() => {
    getIpAddress()
  }, [])

  const isMobile = useMediaQuery({ query: "(max-width: 554px)" })

  const [appliciantname, setAppliciantname] = useState("")
  const [phoneNumb, setPhoneNumb] = useState("")
  const [appliciantid, setAppliciantid] = useState("")
  const [hkid1, setHkid1] = useState("")
  const [hkid2, setHkid2] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [period, setPeriod] = useState("")
  const [purpose, setPurpose] = useState("")
  const [amount, setAmount] = useState("")
  const [confirm1, setConfirm1] = useState(false)

  const nav = useNavigate()

  const CleanData = () => {
    setConfirm1(false)
    setAppliciantname("")
    setAppliciantid("")
    setHkid1("")
    setHkid2("")
    setPhoneNumb("")
    setPaymentMethod("")
    setPurpose("")
    setPeriod("")
    setAmount("")
  }

  useEffect(() => {
    if (hkid1.length >= 8) {
      setHkid1(hkid1.slice(0, -1))
    }
    if (hkid2.length >= 2) {
      setHkid2(hkid2.slice(0, -1))
    }

    setAppliciantid(hkid1 + hkid2)
  }, [hkid1, hkid2])

  const handleSubmit = () => {
    if (ValidateData()) {
      const data = {
        name: appliciantname.trim(),
        phone: parseInt(phoneNumb.trim()),
        idCard: appliciantid.trim(),
        paymentMethod: paymentMethod.trim(),
        period: period.trim(),
        purpose: purpose.trim(),
        amount: parseInt(amount.trim()),
        agreeTerms1: confirm1,
        ipAddress: ipAddress,
        timestamp: moment()
          .utcOffset(8)
          .format("YYYY-MM-DD HH:mm:ss"),
      }

      try {
        fetch(ENV + "/api/v1/application", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (res.ok) {
              nav("/thankyou")
            }
          })
          .catch((err) => {
            console.log(err.message)
          })
          .finally(() => {
            CleanData()
          })
      } catch (error) {
      } finally {
        CleanData()
      }
    }
  }

  const validatePhoneInput = (val) => {
    if (val === "") {
      return false
    } else {
      const result = isMobilePhone(val, "en-HK")
      return result
    }
  }

  const ValidateData = () => {
    let valid = true
    if (appliciantname.replace(/\s/g, "") === "") {
      alert("請填寫個人姓名")
      valid = false
    }
    if (
      validatePhoneInput(phoneNumb) === false ||
      phoneNumb === "" ||
      phoneNumb.trim().length > 8
    ) {
      alert("請填寫八位的香港電話號碼")
      valid = false
    }

    if (paymentMethod === "") {
      alert("請選擇支薪方式")
      valid = false
    }
    if (purpose === "") {
      alert("請選擇貸款種類")
      valid = false
    }
    if (period === "") {
      alert("請選擇還款期數")
      valid = false
    }

    if (!isValidHKID(appliciantid)) {
      alert("請填寫正確身分證號碼")
      valid = false
    }

    if (amount === "" || isNaN(amount)) {
      alert("請填寫正確的貸款金額")
      valid = false
    }
    if (!!!confirm1) {
      alert("請確認條款")
      valid = false
    }
    return valid
  }

  const paymentMethodList = ["支票", "自動轉賬", "現金", "其他"]
  const loanCatList = ["私人貸款", "結餘轉戶", "業主私人貸款", "中小企貸款"]
  const periodList = ["3", "6", "12", "24", "36", "48", "60"]

  return (
    <>
      {isDesktopOrLaptop && (
        <div className={css.container}>
          {/* <img src={square} className={css.square} /> */}
          <Box
            sx={{
              "& > :not(style)": {
                m: 2,
                maxWidth: "1300px",
                minWidth: "320px",
                minHeight: "450px",
              },
            }}
            //selector selects all direct children of the Box component that are not style elements
          >
            <div className={css.outer_border}>
              <div className={css.inner_border}>
                <div className={css.title}>
                  <h2>立即申請</h2>
                </div>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  padding="1.5rem"
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">姓名</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="e.g. 陳大文"
                          value={appliciantname}
                          onChange={(e) => setAppliciantname(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">支薪方式</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl fullWidth>
                          <Select
                            labelId="paymentMethod_label"
                            id="paymentMethod_id"
                            size="small"
                            value={paymentMethod}
                            label=""
                            onChange={(e) => {
                              setPaymentMethod(e.target.value)
                            }}
                            className={css.select}
                          >
                            {paymentMethodList.map((item) => (
                              <MenuItem
                                key={item}
                                className={css.menuItem}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">聯絡電話</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          size="small"
                          value={phoneNumb}
                          onChange={(e) => setPhoneNumb(e.target.value)}
                          placeholder="e.g. 12345678"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">貸款種類</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl fullWidth>
                          <Select
                            labelId="purpose_label"
                            id="purpose_id"
                            size="small"
                            value={purpose}
                            label=""
                            onChange={(e) => {
                              setPurpose(e.target.value)
                            }}
                          >
                            {loanCatList.map((item) => (
                              <MenuItem
                                key={item}
                                className={css.menuItem}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">身分證號碼</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          value={hkid1}
                          onChange={(e) => setHkid1(e.target.value)}
                          placeholder="e.g. A123456"
                          sx={{ width: "68%", marginRight: "2%" }}
                        />
                        <TextField
                          size="small"
                          value={hkid2}
                          onChange={(e) => setHkid2(e.target.value)}
                          placeholder="(7)"
                          sx={{ width: "30%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">還款期數(月)</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl fullWidth>
                          <Select
                            labelId="period_label"
                            id="period_id"
                            size="small"
                            value={period}
                            label=""
                            onChange={(e) => {
                              setPeriod(e.target.value)
                            }}
                          >
                            {periodList.map((item) => (
                              <MenuItem
                                key={item}
                                className={css.menuItem}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <FormLabel variant="subtitle1">貸款金額</FormLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <CurrencyInput
                          id="currency"
                          name="currency"
                          placeholder="HK$ 50,000"
                          // defaultValue={0}
                          decimalsLimit={2}
                          prefix={"HK$ "}
                          onValueChange={(value, name) => setAmount(value)}
                          className={css.currencyInput}
                        />
                        {/* <TextField
                          fullWidth
                          size="small"
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={11}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={confirm1}
                                size="small"
                                sx={{ marginTop: "1rem" }}
                                onChange={(e) => {
                                  setConfirm1(e.target.checked)
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontSize={"11px"}
                                color="#77bf27"
                                style={{ paddingTop: "1rem" }}
                              >
                                *本人已年滿18歲，閱讀並同意此網上的貸款細節及條款。
                                本人明白和同意，在此申請表提供的個人資料將記錄於資料系統作申請用途，在未獲得您同意前，不會向第三方披露你的個人資料。
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} textAlign="right" paddingRight="40px">
                        <SubmitButton
                          variant="contained"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          提交
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </div>
      )}

      {isMobile && (
        <div className={css.container}>
          {/* <img src={square} className={css.square} /> */}
          <Box
            sx={{
              "& > :not(style)": {
                m: 2,
                maxWidth: "1300px",
                minWidth: "320px",
                minHeight: "450px",
              },
            }}
            //selector selects all direct children of the Box component that are not style elements
          >
            <div className={css.outer_border}>
              <div className={css.inner_border}>
                <div className={css.title}>
                  <h2>立即申請</h2>
                </div>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">姓名</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          size="small"
                          value={appliciantname}
                          onChange={(e) => setAppliciantname(e.target.value)}
                          placeholder="e.g. 陳大文"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">支薪方式</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <Select
                            labelId="paymentMethod_label"
                            id="paymentMethod_id"
                            size="small"
                            value={paymentMethod}
                            label=""
                            onChange={(e) => {
                              setPaymentMethod(e.target.value)
                            }}
                            className={css.select}
                          >
                            {paymentMethodList.map((item) => (
                              <MenuItem
                                key={item}
                                className={css.menuItem}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">聯絡電話</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          size="small"
                          value={phoneNumb}
                          onChange={(e) => setPhoneNumb(e.target.value)}
                          placeholder="e.g. 12345678"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">貸款種類</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <Select
                            labelId="purpose_label"
                            id="purpose_id"
                            size="small"
                            value={purpose}
                            label=""
                            className={css.select}
                            onChange={(e) => {
                              setPurpose(e.target.value)
                            }}
                          >
                            {loanCatList.map((item) => (
                              <MenuItem
                                key={item}
                                className={css.menuItem}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center "
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">身分證號碼</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        {/*                         
                        <TextField
                          fullWidth
                          size="small"
                          value={appliciantid}
                          onChange={(e) => setAppliciantid(e.target.value)}
                          placeholder="e.g. A12345678"
                        /> */}
                        <TextField
                          size="small"
                          value={hkid1}
                          onChange={(e) => setHkid1(e.target.value)}
                          placeholder="e.g. A123456"
                          sx={{ width: "68%", marginRight: "2%" }}
                        />
                        <TextField
                          size="small"
                          value={hkid2}
                          onChange={(e) => setHkid2(e.target.value)}
                          placeholder="(7)"
                          sx={{ width: "30%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">貸款金額</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <CurrencyInput
                          id="currency"
                          name="currency"
                          placeholder="HK$ 50,000"
                          // defaultValue={0}
                          decimalsLimit={2}
                          prefix={"HK$ "}
                          onValueChange={(value, name) => setAmount(value)}
                          className={css.currencyInput}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={10}>
                        <FormLabel variant="subtitle1">還款期數</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <Select
                            labelId="period_label"
                            id="period_id"
                            size="small"
                            value={period}
                            label=""
                            onChange={(e) => {
                              setPeriod(e.target.value)
                            }}
                          >
                            {periodList.map((item) => (
                              <MenuItem
                                key={item}
                                className={css.menuItem}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={11}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={confirm1}
                                size="small"
                                sx={{ marginTop: "1rem" }}
                                onChange={(e) => {
                                  setConfirm1(e.target.checked)
                                }}
                              />
                            }
                            label={
                              <Typography
                                fontSize={"11px"}
                                color="#77bf27"
                                style={{ paddingTop: "1rem" }}
                              >
                                *本人已年滿18歲，閱讀並同意此網上的貸款細節及條款。
                                本人明白和同意，在此申請表提供的個人資料將記錄於資料系統作申請用途，在未獲得您同意前，不會向第三方披露你的個人資料。
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        textAlign="right"
                        paddingRight="40px"
                        paddingBottom="1rem"
                      >
                        <SubmitButton
                          variant="contained"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          提交
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </div>
      )}
    </>
  )
}

export default ApplicationForm
