import ReactChatbot from "./chatbot/ReactChatbot"
import FbBtn from "./FbBtn"
import "./floatBtnlist.css"
import PhotoBtn from "./FloatingBtn/PhotoBtn"
import WhatsappBtn from "./WhatsappBtn"
import { ENV } from "../config"
import { useEffect, useState } from "react"
import useFetch from "../codeHelper/useFetch"

const FloatBtnList = () => {
  const { data } = useFetch(ENV + "/api/v1/content")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    setPhone(data?.MT.find((item) => item.title === "phone")?.description)
  }, [data, phone])

  return (
    <div className="floatBtnList">
      <div className="floatBtnList-item">
        <PhotoBtn phone={phone} />
      </div>
      {/* <div className="floatBtnList-item">
        <WhatsappBtn  phone1 ={phone1} phone2 = {phone2}/>
      </div> */}
      <div className="floatBtnList-item">
        <FbBtn />
      </div>
      {/* <div className="floatBtnList-item">
        <ReactChatbot />
      </div> */}
    </div>
  )
}

export default FloatBtnList
