import Banner from '../component/Banner';
import Footer from '../component/Footer';
import bg from '../images/loanService/loanservice_bg.png';
import { Helmet } from 'react-helmet';
import { FormDatatext } from '../codeHelper/useFormatDatatext';
const CollectInFoStatement = ({ content }) => {
  const text = `本通知為知會客戶有關捷盈財務有限公司 (“捷盈財務”) 的資料政策。
  (a) 客戶在申請開立戶口，延續戶口及建立或延續信貸、或要求捷盈財務提供其他服務時，需要不時向捷盈財務提供有關的資料。
  (b) 若未能向捷盈財務提供有關資料會導致捷盈財務無法開立或延續戶口或建立或延續信貸或提供捷盈財務其他服務。
  (c) 在客戶與捷盈財務的正常金融業務往來過程中，捷盈財務亦會收集到或接收有關的客戶的資料，該等資料包括但不限於親身或以電話或網上申請貸款或延續貸款賬戶，包括經信貸資料服務機構所獲得的資料。
  (d) 有關的客戶資料將可能會被捷盈財務或該等資料的接收人用於下列用途 :
  (i) 為提供或處理服務，信貸授信的申請及為客戶提供服務和信貸便利所涉及之日常運作；
  (ii) 在客戶申請信貸時及於每年 ( 通常一次或多於一次 ) 的定期或特別信貸覆核時，進行信用檢查；
  (iii) 協助其他放債人及/或財務機構、信用卡或消費卡發行公司及收數公司作信貸檢查及追討債務；
  (iv) 確保客戶的信用維持良好；
  (v) 為客戶設計財務服務或有關產品；
  (vi) 推廣服務、產品及其他標的（詳情請參閱以下（f）段）；
  (vii) 計算客戶與捷盈財務之間的賬項及／或債務；
  (viii) 向客戶及為客戶提供擔保或抵押的人仕或與客戶以共同借款人身份所獲貸款而追收欠款；
  (ix) 履行根據下列適用於捷盈財務或其任何分行或為依循及施行任何預期捷盈財務會遵從的監管或其他機構所發出的指引作出披露及使用資料的義務、規定或安排：
  (1) 不論於香港特別行政區 (“香港”) 境內或境外及不論目前或將來存在的對其具法律約束力或適用的任何法律；
  (2) 不論於香港境內或境外及不論目前或將來存在的任何法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會作出或發出的任何指引或指導；
  (3) 捷盈財務或其任何分行因其位於或跟相關本地或外地的法律、監管、政府、稅務、執法或其他機關，或自律監管或行業組織或協會的司法管轄區有關的金融、商業、業務或其他利益或活動，而向該等本地或外地的法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會承擔或被彼等施加的任何目前或將來的合約或其他承諾；
  
  (x) 遵守與捷盈財務同一集團的公司為符合制裁或預防或偵測清洗黑錢、恐怖分子融資活動或其他非法活動的任何方案就於捷盈財務集團內共用資料及資訊及 / 或資料及資訊的任何其他使用而指定的任何義務、要求、政策、程序、措施或安排；
  
  (xi) 使捷盈財務的實在或建議承讓人，或捷盈財務對客戶的權利的參與人或附屬參與人評核意圖成為轉讓，參與或附屬參與的交易；
  
  (xii) 進行核對程序；
  
  (xiii) 編制及維持捷盈財務的信貸評分模式；及
  
  (xiv) 與上述有關的用途。
  
  (e) 捷盈財務會把客戶的資料保密，但捷盈財務可能會把有關資料提供給下述各方 ( 包括在香港特別行政區以內或以外的地方 ) 作第 (d) 段列出的用途 :
  (i) 任何中間人、承包商、或提供行政、電訊、電子資金轉帳服務、電腦、付款、收賬或其他和捷盈財務業務運作有關的服務的第三者服務供應商；
  
  (ii) 捷盈財務的任何分行、附屬公司、控股公司、有聯繫公司或相關聯成員；
  
  (iii) 任何對捷盈財務有保密責任的人，包括對捷盈財務有保密資料承諾的及與捷盈財務同一集團的公司；
  
  (iv) 信貸資料服務機構；而在客戶欠賬時，則可將該等資料提供給追款公司或律師行；
  
  (v) 捷盈財務或其任何分行根據對捷盈財務或其任何分行具法律約束力或適用的任何法律規定，或根據及為符合任何法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會作出或發出的並期望捷盈財務或其任何分行遵守的任何指引或指導，或根據捷盈財務或其任何分行向本地或外地的法律、監管、政府、稅務、執法或其他機關，或金融服務供應商的自律監管或行業組織或協會的任何合約或其他承諾 ( 以上不論於香港特別行政區境內或境外及不論目前或將來存在的），而有義務或以其他方式被要求向其披露該等資料的任何人士；
  
  (vi) 提供或擬提供擔保或第三方保證以擔保或保證客戶的責任的任何一方，及客戶如在某一項向捷盈財務申請貸款時是與其他人仕以共同借款人身份所作出的該等其他人仕；及
  (1) 捷盈財務的集團成員；
  (2) 第三方金融機構、保險商、信用卡公司、證券及投資服務供應商；
  (3) 第三方回贈、客戶獎勵或優惠計劃供應商；
  (4) 捷盈財務及聯營夥伴 ( 該等聯營夥伴的名稱載於相關服務及產品 ( 視乎情況而定 ) 的申請表及 / 或宣傳單張 / 海報中 ) ；
  (5) 慈善或非牟利機構；及
  (6) 捷盈財務為 (d) (vi) 段所列出的任何用途而聘用的外聘服務供應商 ( 包括但不限於代客寄件中心、電訊公司、電話推廣及直銷公司、通訊中心、資料處理公司及資訊科技公司 ) 。
  該等資料可轉傳至香港以外的地方。
  
  (f) 在直接促銷中使用資料
  捷盈財務擬把客戶資料用於直接促銷，而捷盈財務為該用途須獲得客戶同意（包括表示不反對）。就此，請注意：
  (i) 捷盈財務可能把捷盈財務不時持有的客戶姓名、聯絡資料、產品及服務組合資料、交易模式及行為、財務背景及人口統計數據用於直接促銷；
  (ii) 可用作促銷下列類別的服務、產品及促銷標的：
  (1) 財務服務及相關產品包括保險產品；
  (2) 獎賞、客戶或會員或優惠計劃及相關服務及產品，若客戶不希望本公司如上述使用其個人資料作直接促銷用途，客戶可通知本公司行使其選擇拒絕促銷，就此客戶無須繳付費用；
  (3) 捷盈財務聯營夥伴提供之服務及產品（該等聯營夥伴的名稱載於相關服務及產品 ( 視乎情況而定 ) 的申請表及 / 或宣傳單張 / 海報中）；及
  (4) 為慈善或非牟利機構用途的捐款及捐贈；
  (iii) 上述服務、產品及促銷標的可能由捷盈財務及 / 或下列各方提供或（就捐款及捐贈而言）徵求：
  (1) 捷盈財務的集團公司；
  (2) 第三方金融機構、保險商、信用卡公司、證券及投資服務供應商；
  (3) 第三方回贈、客戶獎勵或優惠計劃供應商；
  (4) 捷盈財務提供的服務及產品；及
  (5) 慈善或非牟利機構；
  (iv) 除由捷盈財務促銷上述服務、產品及促銷標的以外，捷盈財務亦擬將以上（f）（i）段所述的資料提供予以上（f）（iii）段所述的全部或任何人士，以供該等人士在促銷該等服務、產品及促銷標的中使用，而捷盈財務為此用途須獲得客戶書面同意（包括表示不反對）；
  (v) 捷盈財務可能因如以上（f）（iv）段所述將資料提供予其他人士而獲得金錢或其他財產的回報。如捷盈財務會因提供資料予其他人士而獲得任何金錢或其他財產的回報，捷盈財務會於以上（f）（iv）段所述徵求客戶同意或不反對時如是通知客戶。
  如客戶不希望捷盈財務如上述使用其資料或將其資料提供予其他人士作直接促銷用途，客戶可通知捷盈財務行使其選擇權拒絕郵寄、電子郵件、流動電話訊息及/或電話促銷，就此客戶無須繳付費用。
  (g) 從互聯網收集個人資料的實務：
  (i) 捷盈財務會按照嚴格的保安及保密標準保障客戶在互聯網提供給捷盈財務的任何資料，敏感性的資料會採用加密法在互聯網上傳輸。
  
  (ii) 當客戶瀏覽捷盈財務網站時，捷盈財務會記錄到訪的人次。網站伺服器亦會記錄客戶的到訪資料，包括客戶的IP地址（包括域名）、瀏覽器類別及設置、語言設定、地理位置、操作系統、之前瀏覽網頁，以及瀏覽時間及網頁（訪客資料）。捷盈財務使用該些資料作維持或改善網站運作，例如，網站解像度、得知最多人瀏覽的網頁等。捷盈財務不會，並且無意使用到訪人士的資料作識辨身份的用途。
  
  (iii) 「曲奇」檔案是由網站伺服器傳送至瀏覽器的小段資訊，這些資料儲存於電腦硬碟中，使網站伺服器能於稍後再從瀏覽器內讀取。這有助網站保存某些使用者的資料。目前，捷盈財務不會在其網站設置「曲奇」檔案，如日後設置「曲奇」檔案，捷盈財務亦只會利用「曲奇」檔案來鑑定特定期間的使用者，而不會把使用者的敏感性資料存置於「曲奇」檔案內。
  
  (iv) 上述在網上收集的個人資料會存置於網站伺服器，但僅只作上述用途，有關資料會在收集後的6個月內删除。
  
  (v) 客戶經網站及／或其超連結所傳送的電子通訊，無論是電子郵件，文字或語音訊息等，其內容均會儲存於捷盈財務的伺服器以用作（d)段的用途，如果捷盈財務最終沒有接納客戶的有關服務申請，此等所收集的資料會在6個月內删除； 如果申請被接納，此等資料將保存6 年。
  
  (vi) 捷盈財務在網站或其連結的申請表格內要求客戶提供的個人資料，如果是註明(*)的欄目，而客戶是未能提供的，捷盈財務未必可以向客戶提供有關的服務。客戶是有權選擇提供或不提供沒有註明（＊）的欄目所要求的資料的。如客戶提供其電話號碼，捷盈財務可以有需要時與客戶聯絡跟進有關服務。
  
  (vii) 就捷盈財務網站包含超連結或連接至其他網站／網址或應用程式，並不表示或暗示捷盈財務對該等超連結、連接、網站／網址或有關其本體身份或資料作任何認証、核實、陳述、批核或認可。就該等超連結、連接的其他網站／網址所提供或附有資訊的內容、應用程式、準確性或遺漏，捷盈財務明確表明概不負責，使用該等超連結、連接或應用程式之風險全由客戶承擔。
  
  (h) 根據個人資料 ( 私隱 ) 條例 (“條例”) 及「個人信貸資料實務守則」，以及任何由私隱專員或其他監管機構所發出之法例或守則，任何客戶有權 :-
  (i) 審查捷盈財務是否持有他的資料及有權查閱有關的資料；
  (ii) 要求捷盈財務改正有關他不準確的資料；
  (iii) 查悉捷盈財務對於資料的政策及實際運用和獲告知捷盈財務持有關於他的個人資料種類；
  (iv) 要求獲告知哪些資料是通常會向信貸資料服務機構或追收公司披露的，以及獲提供進一步資料，藉以向有關信貸資料服務機構或追收公司提出查閱和改正資料要求；
  (v) 就捷盈財務向信貸資料服務機構提供的任何帳戶資料（為免生疑問，包括任何帳戶還款資料），於全數清還欠帳後結束帳戶時，指示捷盈財務要求信貸資料服務機構從其資料庫中刪除該等帳戶資料，但指示必須於帳戶結束後五年內提出及於緊接終止信貸前五年內沒有任何拖欠為期超過 60 日的欠款記錄。帳戶還款資料包括上次到期的還款額，上次報告期間（即緊接捷盈財務上次向信貸資料服務機構提供帳戶資料前不多於 31 日的期間）所作還款額，剩餘可用信貸額或未償還款額及欠款資料（即過期欠款額及逾期還款日數，清還過期欠款的日期，及全數清還拖欠為期超過 60 日的欠款的日期（如有））。
  (i) 如帳戶出現任何拖欠還款情況，除非拖欠金額在由拖欠日期起計60 日屆滿前全數清還或已撇帳（因破產令導致撇帳除外），否則帳戶還款資料（定義見以上（g）（v）段）會在全數清還該拖欠還款後被信貸資料服務機構繼續保留直至自欠款全數清還之日計滿五年為止。
  (j) 如客戶因被頒布破產令而導致任何帳戶金額被撇帳，不論帳戶還款資料有否顯示任何拖欠為期超過 60 日的還款，該帳戶還款資料（定義見以上（g）（v）段））會在全數清還該拖欠還款後被信貸資料服務機構繼續保留計滿五年為止，或由客戶提出證據通知信貸資料服務機構其已獲解除破產令後保留多五年（以較早出現的情況為準）。
  (k) 捷盈財務可為信貸審核用途不時查閱信貸資料服務機構的資料庫。而該等審核或牽涉捷盈財務對下列事項的考慮：
  (i) 增加信貸限額；
  (ii) 對信貸作出限制 ( 包括取消或減少信貸限額 ) ；或
  (iii) 對有關客戶安排或實行債務償還安排。
  (l) 根據條例的規定，捷盈財務有權就處理任何提出查閱資料的要求收取合理費用。
  (m) 任何關於資料查閱或改正資料，或關於資料政策及實際應用或資料種類的要求，請聯絡 :-
  資料捷盈財務保障主任
  (n) 捷盈財務可為考慮任何信貸申請，向信貸資料服務機構索取客戶的信貸報告。如客戶欲查閱信貸報告，捷盈財務可在收取合理費用下，告知相關機構的聯絡資料。
  (o)「客戶」一詞包括借貸人及擔保人，其本人或該公司 ( 及後者之董事、股東或公司人員)或非屬法人團體(獨資者或合夥人)。「信貸」意指個人信貸及商業信貸 ( 包括分期租購或租用 )。文中提及之單一性別包括其他性別，而單數詞包括雙數詞。
  (p) 捷盈財務或如前文第 (e) 條所指的任何人士可在或可向其本身認為合適的國家處理、保存及轉移或披露客戶的資料。有關資料亦可根據該國當地的慣例和法律、法規和規定 ( 包括任何政府行政措施和政令 ) 而作出發放或披露。
  (q) 本文並不限制客戶根據條例所擁有之權利。
  (r) 當客戶收悉本通知，本通知將被視作客戶已或企圖與捷盈財務簽訂的合約、協議、信貸／貸款協議書及其他具約束力文件等的其中一部份。`;
  return (
    <div className="ordinance">
      <Helmet>
    <title>{content?.MT[10].metaTitle}</title>
    <meta name='description' content={content?.MT[10].metaDescription}/>
    <meta name='keywords' content={content?.MT[10].metaKeywords}/>
    </Helmet>
      {/* <Banner name={'放債人條例'} name_ch={''} bg_url={bg} /> */}
      <div className="container mt-5 mb-5">
        <h1 className="text-center mb-5">個人資料收集聲明</h1>
        <p>捷盈財務有限公司</p>
        <p>有關客戶資料的客戶通知書</p>
        <p>{text}</p>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default CollectInFoStatement;
