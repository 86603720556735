import '../balanceTransfer/balanceTransfer.css';
import { useEffect } from 'react';
import HomeSection3 from '../page_section/HomeSection3';
import HomeSection5 from '../page_section/HomeSection5';
import PrivateLoanSection1 from '../page_section/PrivateLoanSection1';
import Banner from '../balanceTransfer/Banner';
import Footer from '../component/Footer';
import { Helmet } from 'react-helmet';
import bg from '../images/balancetransfer/balance_bg.jpg';
// import style from './balanceTransfer.module.css';
import style from '../css/services.module.css';
import ApplicationForm from '../component/ApplicationForm/ApplicationForm';

//mobile
import { useMediaQuery } from 'react-responsive';
import BannerSquare from '../component/BannerSquare/BannerSquare';
import icon1 from '../images/balancetransfer/bt_icon1.png';
import icon2 from '../images/balancetransfer/bt_icon2.png';
import icon3 from '../images/balancetransfer/bt_icon3.png';
import icon4 from '../images/balancetransfer/bt_icon4.png';
import squaregp_bg from '../images/squaregp.png';

const BalanceTransfer = ({ content }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });

  const data = [
    {
      icon: icon1,
      text: '彈性靈活',
      text2: '隨意自選，想點清? 自己揀',
    },
    {
      icon: icon2,
      text: '省時方便',
      text2: '不限一筆清貸款額，即日取款',
    },
    {
      icon: icon3,
      text: '專業快捷',
      text2: '專業分析客戶需求，即時提供多項方案',
    },
    {
      icon: icon4,
      text: '集中還款',
      text2: '一筆清最高達月薪10倍',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{content?.MT[3].metaTitle}</title>
        <meta name="description" content={content?.MT[3].metaDescription} />
        <meta name="keywords" content={content?.MT[3].metaKeywords} />
      </Helmet>
      {!isMobile && (
        <div className="BalanceTransfer">              
          <div className="BalanceTransfer-section1-container">
            <Banner
              name={'Balance Transfer'}
              name_ch={'結餘轉戶'}
              bg_url={bg}
            />
          </div>
          <div className="BalanceTransfer-section2-container">
            <PrivateLoanSection1
              title={
                '卡數、貸款、透支集中處理，按還款能力制定還款年期更輕鬆，餘額可自由運用'
              }
              desc={''}
            />
          </div>

          <div className="BalanceTransfer-section3_container section3_bg">
            <ApplicationForm />
          </div>
          <div className="BalanceTransfer-section4_container section5_bg">
            <HomeSection5 />
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          <div className={`${style.banner} ${style.banner_balanceTransfer}`}>
            <h2 className={style.banner_text}>結餘轉戶</h2>
          </div>

          <BannerSquare
            header={'按揭及業主貸款'}
            data={data}
            width={'50%'}
            height={'300px'}
            top={'unset'}
          />

          <div className={style.text1}>
            <img src={squaregp_bg} alt="" className={style.squaregp_bg} />
            <h4>
              卡數、貸款、透支集中處理，按還款能力制定還款年期更輕鬆，餘額可自由運用
            </h4>
          </div>
          <div className={style.text2}>
            <p>
              註: 根據《放債人條例》年利率最高不超過48%。
              供款期由6個月至個120月常見還款例子:
              年利率為30%情況下的貸款HK$10,000，還款期數12期，每期還款額為
              HK$965
            </p>
          </div>

          <div>
            <ApplicationForm />
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default BalanceTransfer;
