import './homeSection.css';
import man from '../images/home/home_calculator_background.png';
import dot_bg from '../images/caltor_dot_bg.png';
import CalculatorContent from '../component/CalculatorContent';
import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import '../css/animation.css';

const HomeSection2 = () => {
  const titleRef = useRef(null);
  const manRef = useRef(null);
  const title2Ref = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 544px)' });

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.pageYOffset);
      //Desktop
      if (!isMobile) {
        if (scrollY > 150) {
          titleRef.current.classList.add('slide-in');
        }
        if (scrollY > 250) {
          manRef.current.classList.add('fade-in-frm-bottom');
        }
        if (scrollY > 700) {
          title2Ref.current.classList.add('fade-in-frm-bottom');
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <div className="home-section2">
      <div
        style={{
          fontSize: '2.5rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          opacity: '0',
        }}
        ref={titleRef}
      >
        <span style={{ color: '#9DD75E' }}>特快</span>網上貸款
      </div>
      <div ref={manRef} className="home-section2-container">
        <div className="home-section2-container-left">
          <img className="calculator-man" src={man} alt="" />
        </div>
        <div className="calculator">
          <img className="calculator-dot-bg" src={dot_bg} />
          <CalculatorContent />
        </div>
      </div>
      <div ref={title2Ref} className="section3_container">
        <div className="d-flex justify-content-center">
          <p>
            可選擇透過網上平台完成整個貸款申請過程，
            <br />
            即時提取現金。
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSection2;
